@import "~antd/dist/antd.css";
/* UTILS */

.h-100 {
  height: 100%;
}

.t-50 {
  background: rgba(255, 255, 255, 0.6);
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-10 {
  margin-right: 10px;
}

.center {
  text-align: center;
}

/* Login */

.background {
  background: rgb(70, 42, 125);
  background: -webkit-linear-gradient(
    315deg,
    rgb(136, 2, 81) 0%,
    rgb(70, 42, 125) 35%,
    rgb(29, 46, 127) 68%,
    rgb(35, 99, 171) 88%,
    rgb(37, 109, 194) 100%
  );
  background: -o-linear-gradient(
    315deg,
    rgb(136, 2, 81) 0%,
    rgb(70, 42, 125) 35%,
    rgb(29, 46, 127) 68%,
    rgb(35, 99, 171) 88%,
    rgb(37, 109, 194) 100%
  );
  background: -ms-linear-gradient(
    315deg,
    rgb(136, 2, 81) 0%,
    rgb(70, 42, 125) 35%,
    rgb(29, 46, 127) 68%,
    rgb(35, 99, 171) 88%,
    rgb(37, 109, 194) 100%
  );
  background: -moz-linear-gradient(
    315deg,
    rgb(136, 2, 81) 0%,
    rgb(70, 42, 125) 35%,
    rgb(29, 46, 127) 68%,
    rgb(35, 99, 171) 88%,
    rgb(37, 109, 194) 100%
  );
  background: linear-gradient(
    135deg,
    rgb(136, 2, 81) 0%,
    rgb(70, 42, 125) 35%,
    rgb(29, 46, 127) 68%,
    rgb(35, 99, 171) 88%,
    rgb(37, 109, 194) 100%
  );
}

.floating-brand {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 225px;
}

.layout-login-card {
  border-radius: 25px;
}

/* Form validation */

.red-alert > .ant-alert-message {
  color: red !important;
}

.red-alert-floating {
  border: 0;
  background: transparent;
}

/* Home */
.layout-card {
  background-color: white;
  border-radius: 25px;
}

.custom-header-card {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 25px 25px 0 0;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #d0cece;
}

.input-error {
  border: 1px solid red;
}

.input-error-text > .ant-alert-message {
  color: red !important;
  font-size: 13px;
}
